<template>
    <b-modal
            @hide="closeModal"
            ref="register"
            id="register"
            :visible="isVisible"
            :title="$t('Espace client')"
            hide-footer
    >
        <div class="container">
            <div class="row">
                <div class="col-12">

                    <p v-if="showMsgExistingUser">{{ $t('__AEV5_Hello! Content de vous revoir! Vous avez déjà un compte Ardennes-Etape. Connectez-vous pour poursuivre.')}}</p>

                    <login-form
                            :encodedEmail="encodedEmail"
                            :errorMsg="errorMsg"
                            @clickForgotPassword="openForgotPassword"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="spacer">
                        <span>{{ $t('__ou') }}</span></div>
                </div>
            </div>

            <login-social-media/>

            <sign-up-button @click="openSignUp"/>
        </div>
    </b-modal>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import { BModal } from 'bootstrap-vue'

    import LoginForm from "Components/ui/LoginForm"
    import LoginSocialMedia from "../LoginSocialMedia";
    import SignUpButton from "./components/ui/SignUpButton";

    const LoginModal = {
        components: {
            BModal,
            LoginForm,
            LoginSocialMedia,
            SignUpButton
        },
        computed: {
            ...mapState('auth/login',{
                encodedEmail : ({email}) => email,
                showMsgExistingUser : ({isExistingUser}) => isExistingUser,
            }),
            ...mapGetters({
                isVisible : 'modals/loginShow',
            })
        },
        props : {
            errorMsg: {
                type: String,
                required: false
            }
        },
        methods : {
            ...mapActions({
                closeModal : 'modals/closeLogin',
                openSignUp : 'modals/openSignUp',
                openForgotPassword : 'modals/openForgotPassword'
            })
        }
    };
    export default LoginModal;
</script>
