<template>
    <b-modal
        no-fade
        @close="closeInput"
        :visible="isVisible"
        no-close-on-backdrop
        no-close-on-esc
        :title="title"
        :hide-header-close="backNavigation"
    >
        <template v-slot:modal-title>
            <back-button @click="closeInput" v-if="backNavigation"/>
            {{ title }}
        </template>

        <stay-localities-selector/>

        <template v-slot:modal-footer>
            <no-preference-button @click="onChooseNoPreference"/>
            <confirm-button @click="closeInput"/>
        </template>
    </b-modal>
</template>
<script>

    import { BModal, BButton } from 'bootstrap-vue'
    import { mapActions, mapGetters } from 'vuex'
    import BackButton from "Components/ui/Modals/BackButton/BackButton";
    import StayLocalitiesSelector
        from "Components/container/MR1/components/container/StayLocalitiesSelector/StayLocalitiesSelector";
    import ConfirmButton from "Components/ui/form/ConfirmButton/ConfirmButton";
    import NoPreferenceButton from "Components/ui/form/NoPreferenceButton/NoPreferenceButton";

    const StayLocalitiesModal = {
        components: {
            BackButton,
            NoPreferenceButton,
            BModal,
            BButton,
            StayLocalitiesSelector,
            ConfirmButton
        },
        computed: {
            title(){
              return this.$t("Où voulez-vous aller ?")
            },
            ...mapGetters({
                isVisible : 'modals/stayLocalitiesShow',
                backNavigation : 'mr1/isFormInModal'
            })
        },
        methods :{
            ...mapActions({
                closeInput : 'mr1/closeInput',
                setNoPreference :'mr1/stayLocalities/setNoPreference'
            }),
            onChooseNoPreference(){
                this.setNoPreference()
                this.closeInput()
            }
        }
    };
    export default StayLocalitiesModal
</script>
