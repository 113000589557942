<template>
    <manual-popover
            :show="show"
            target="stay-locality-button"
            @clickOutside="closeInput"
            container="mr1"
    >

        <stay-localities-selector/>
        <no-preference-button @click="onChooseNoPreference"/>
    </manual-popover>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    import ManualPopover from "Components/ui/ManualPopover";
    import StayLocalitiesSelector
        from "Components/container/MR1/components/container/StayLocalitiesSelector/StayLocalitiesSelector";
    import NoPreferenceButton from "Components/ui/form/NoPreferenceButton";


    export default {
        name: "StayLocalityPopover",
        components: {
            ManualPopover,
            StayLocalitiesSelector,
            NoPreferenceButton
        },
        computed : {
            ...mapGetters({
                show : 'mr1/showPopoverLocalities'
            })
        },
        methods : {
            ...mapActions({
                closeInput: 'mr1/closeInput',
                setNoPreference :'mr1/stayLocalities/setNoPreference'
            }),
            onChooseNoPreference(){
                this.setNoPreference()
                this.closeInput()
            }
        }
    }
</script>

<style scoped>

</style>
