import BaseBind from "Utils/BaseBindClass"
import Cookies from 'js-cookie'

export default class CookieBar extends BaseBind {
    events = [
        [
            "click",
            ["#cookiesInfoOK"],
            "closeAndSetCookie"
        ],
    ]

    constructor({el, store, i18n, data}) {
        super({el, store, i18n}).init()
    }
    closeAndSetCookie = () => {
        Cookies.set('luInfoCookie', 1, { expires: 365 });
        this.$el.classList.add("hide");
    }
}
