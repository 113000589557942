<template>
    <b-form
            @submit="onSubmit"
            method="post"
            :id="id">
        <div class="row">
            <div class="col-12">
                <b-alert v-if="errorMsg" show variant="warning">{{ errorMsg }}</b-alert>
                <email-input v-model="email" ref="email" name="emailLog"/>
                <password-input v-model="password" ref="password" name="mdpLog"/>
                <remember-me name="resterConnecte"/>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <forgot-password-button @click="onClickForgotPassword"/>
            </div>
            <div class="col-6 float-right">
                <continue-submit-button></continue-submit-button>
            </div>
        </div>
        <token-input/>
    </b-form>
</template>

<script>

    import { BForm, BButton, BAlert } from 'bootstrap-vue'
    import EmailInput from "Components/ui/form/EmailInput";
    import PasswordInput from "Components/ui/form/PasswordInput";
    import RememberMe from "Components/ui/form/RememberMe";
    import ContinueSubmitButton from "Components/ui/form/ContinueSubmitButton";
    import TokenInput from "Components/container/TokenInput";
    import ForgotPasswordButton from "Components/ui/form/ForgotPasswordButton";

    export default {
        name: 'LoginForm',
        components:{
            BAlert,
            BForm,
            BButton,
            EmailInput,
            PasswordInput,
            RememberMe,
            ContinueSubmitButton,
            TokenInput,
            ForgotPasswordButton
        },
        props : {
            id : {
              type: String
            },
            encodedEmail: {
                type: String
            },
            errorMsg: {
                type: String
            }
        },
        data (){
            return {
                email : this.$props.encodedEmail || null,
                password: null
            }
        },
        computed: {
            isFormValid(){
                return this.$refs.email.state
                    && this.$refs.password.state
            },
        },
        methods: {
            invalidEmptyInput(inputName){
                if(this.$refs[inputName].state === null && this[inputName] === null){
                    this[inputName] = ''
                }
            },
            invalidForm(){
                this.invalidEmptyInput('email');
                this.invalidEmptyInput('password');
            },
            onSubmit (e) {
                if(!this.isFormValid) {
                    e.preventDefault();
                    this.invalidForm();
                }
            },
            onClickForgotPassword(){
                this.$emit('clickForgotPassword')
            }
        }
    }
</script>
