<template>
    <manual-popover
            :show="show"
            target="stay-date-button"
            @clickOutside="closeInput"
            container="mr1"
    >
        <stay-date-and-type-selector/>
        <reset-and-confirm-button @reset="resetDate" @confirm="setDefaultIfNothingAndCloseInput"/>
    </manual-popover>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex'
    import ManualPopover from "Components/ui/ManualPopover";
    import StayDateAndTypeSelector from "Components/container/MR1/components/container/StayDateAndTypeSelector/StayDateAndTypeSelector";
    import ResetAndConfirmButton from "Components/ui/form/ResetAndConfirmButton";

    export default {
        name: "StayDatesPopover",
        components:{
            ManualPopover,
            StayDateAndTypeSelector,
            ResetAndConfirmButton
        },
        computed : {
            ...mapGetters({
                show : 'mr1/showPopoverDates',
            }),
        },
        methods:{
          scrollToMr1() {
            let mr1 = document.getElementById("mr1Position");
            if( ! mr1) {
              mr1 = document.getElementById("mr1Scroll");
            }
            mr1.scrollIntoView({block: 'nearest'});
          },
          ...mapActions({
                resetDate : 'mr1/stayDateSelected/reset',
                setDefaultStayTypeIfNothing : 'mr1/stayDateSelected/setDefaultStayTypeIfNothing',
                closeInput : 'mr1/closeInput',
            }),
            setDefaultIfNothingAndCloseInput(){
                this.setDefaultStayTypeIfNothing()
                this.closeInput()
                this.scrollToMr1()
            },
        }

    }
</script>
<style>

</style>
