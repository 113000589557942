<template>
    <div>
        <input type="hidden" name="Chiens" value="2p"/>
        <input type="hidden" name="Pers" value="2p"/>
        <input type="hidden" name="Mois" value="2020-02-00"/>
        <input type="hidden" name="TypeSejour" value="Week-end"/>
        <input type="hidden" name="DateDispo" value="2020-02-21_to_2020-02-24"/>
    </div>
</template>

<script>
    export default {
        name: "Inputs"
    }
</script>

<style scoped>

</style>
