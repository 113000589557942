<template>
    <b-modal
        no-fade
        @close="closeInput"
        :visible="isVisible"
        no-close-on-backdrop
        no-close-on-esc
        :title="title"
        :hide-header-close="backNavigation"
    >
        <template v-slot:modal-title>
            <back-button @click="closeInput" v-if="backNavigation"/>
            {{ title }}
        </template>

        <stay-group-form-mr1/>

        <template v-slot:modal-footer>
            <reset-and-confirm-button @reset="resetGroup" @confirm="setDefaultIfNothingAndClose"/>
        </template>
    </b-modal>
</template>
<script>

    import { BModal } from 'bootstrap-vue'
    import { mapGetters, mapActions } from 'vuex'

    import BackButton from "Components/ui/Modals/BackButton/BackButton";
    import StayGroupFormMr1 from "Components/container/MR1/components/container/StayGroupFormMr1";
    import ResetAndConfirmButton from "Components/ui/form/ResetAndConfirmButton";

    export default {
        name:'StayGroupModal',
        components: {
            BModal,
            BackButton,
            StayGroupFormMr1,
            ResetAndConfirmButton
        },
        computed: {
            title(){
                return this.$t("Avec qui partez-vous ?")
            },
            ...mapGetters({
                isVisible : 'modals/stayGroupShow',
                backNavigation : 'mr1/isFormInModal'
            })
        },
        methods :{
            ...mapActions({
                resetGroup:'mr1/stayGroup/reset',
                closeInput:'mr1/closeInput',
                setDefaultIfNothing : 'mr1/stayGroup/setDefaultIfNothing'
            }),
            setDefaultIfNothingAndClose(){
                this.setDefaultIfNothing()
                this.closeInput()
            },
        }
    };
</script>
