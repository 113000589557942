<template>
    <b-form-checkbox
            :name="name"
            value="on"
            v-on="$listeners"
    >
        {{ $t('__AEV5_Rester connecté') }}
    </b-form-checkbox>
</template>

<script>

    import { BFormCheckbox } from 'bootstrap-vue'

    export default {
        name: "RememberMe",
        components: {
            BFormCheckbox
        },
        props: {
            name: {
                type: String
            },
            value : {
                type : [String, Boolean]
            }
        }
    }
</script>

<style scoped>

</style>
