import M from "Constants/mutations-types";
import C from "Constants";

import folder from "./folder";

import stayLocalities from './stayLocalities'
import stayMonths from './stayMonths'

import showDate from './showDate'
import stayDateSelected from './stayDateSelected'
import stayDates from './stayDates'
import stayTypes from 'Store/stayTypes'
import stayGroup from "./stayGroup"


export const state = {
    modalOpen : false,
    concatenatedOnMobile : true,
    openInput : null//C.STAY_GROUP
}

export const getters = {
    isFormInModal : ({ concatenatedOnMobile }, getters, rootState, rootGetters) =>  rootGetters['breakpoint/isMobile'] && concatenatedOnMobile,

    isOpenInputLocalities : ({openInput}) => openInput === C.STAY_LOCALITIES,
    isOpenInputDates : ({openInput}) => openInput === C.STAY_DATES,
    isOpenInputGroup : ({openInput}) => openInput === C.STAY_GROUP,

    canOpenPopover : (state, getters, rootState, rootGetters) =>  rootGetters['breakpoint/isNotMobile'] && rootGetters['modals/noModalOpen'],

    showPopoverLocalities : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputLocalities'],
    showPopoverDates : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputDates'],
    showPopoverGroup : ({openInput}, getters) =>  getters['canOpenPopover'] && getters['isOpenInputGroup'],

}

export const mutations = {
    [M.OPEN_INPUT](state, input){
        state.openInput = input
    },
    [M.CLOSE_INPUT](state){
        state.openInput = null
    },
    [M.MR1_OPEN_MODAL](state){
        state.modalOpen = true
    },
    [M.MR1_CLOSE_MODAL](state){
        state.modalOpen = false
    }
}

export const actions = {
    openInput : ({commit},{input}) => commit(M.OPEN_INPUT, input),
    closeInput : ({commit}) => commit(M.CLOSE_INPUT),

    openInputLocalities : ({dispatch}) => dispatch('openInput', { input : C.STAY_LOCALITIES }),
    openInputDates : ({dispatch}) => dispatch('openInput', { input : C.STAY_DATES }),
    openInputGroup : ({dispatch}) => dispatch('openInput', { input : C.STAY_GROUP }),

    openModal :  ({commit}) => commit(M.MR1_OPEN_MODAL),
    closeModal :  ({commit}) => commit(M.MR1_CLOSE_MODAL),
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
    modules : {
        folder,
        stayLocalities,
        stayMonths,
        showDate,
        stayDateSelected,
        stayDates,
        stayTypes,
        stayGroup
    }
}
