<template>
    <section>
        <stay-date-selector
            :stayTypes="stayTypes"
            :stayType="stayType"
            @change="onStaySelected"
            @changeStayType="onChangeStayType"
            @onClickMonth="onChangeMonth"
        />

            <div class="legende">
                <div class="jourDepart">{{$t("Jour d'arrivée")}}</div>
                <div class="sejourChoisi">{{$t("__AEV5_Séjour choisi")}}</div>
                <div class="sejourDispo">{{$t("__AEV5RDAE_Titre calendrier fiche location.")}}</div>
            </div>

    </section>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    import StayDateSelector from "Components/container/MR1/components/container/StayDateSelector/StayDateSelector";

    export default {
        name: "StayDateAndTypeSelector",
        components: {
            StayDateSelector,
        },
        computed : {
            ...mapGetters({
                stayTypes : "stayTypes/stayTypeAsOption",
                isMobile : "breakpoint/isMobile",
                stayType : 'mr1/stayDateSelected/selectedTypeOrDefault'
            }),
        },
        methods : {
            ...mapActions({
                setStayTypeSelected: 'mr1/stayDateSelected/setStayTypeSelected',
                setStayDateSelected : 'mr1/stayDateSelected/setDate',
                setMonthSelected : 'mr1/stayDateSelected/setMonth'
            }),
            onChangeStayType(type){
                this.setStayTypeSelected(type)
            },
            onStaySelected(stay){
                this.setStayDateSelected(stay)
            },
            onChangeMonth(month){
                this.setMonthSelected(month)
            }
        }
    }
</script>

<style scoped>

</style>
