<template>
    <div>
        <reset-button @click="reset"/>
        <confirm-button @click="confirm"/>
    </div>
</template>

<script>

    import ConfirmButton from "Components/ui/form/ConfirmButton/ConfirmButton";
    import ResetButton from "Components/ui/form/ResetButton/ResetButton";

    export default {
        name: "ResetAndConfirmButton",
        components:{
            ResetButton, ConfirmButton
        },
        methods:{
            reset(){
                this.$emit("reset")
            },
            confirm(){
                this.$emit("confirm")
            }
        }
    }
</script>

<style scoped>

</style>
