<template>
    <manual-popover
            :show="show"
            target="stay-group-button"
            @clickOutside="closeInput"
            container="mr1"
    >
        <stay-group-form-mr1/>
        <reset-and-confirm-button @reset="resetGroup" @confirm="setDefaultIfNothingAndOpenDate"/>
    </manual-popover>
</template>
<script>

    import { mapActions, mapGetters } from 'vuex'
    import ManualPopover from "Components/ui/ManualPopover"
    import StayGroupFormMr1 from "Components/container/MR1/components/container/StayGroupFormMr1"
    import ResetAndConfirmButton from "Components/ui/form/ResetAndConfirmButton"

    export default {
        name:'StayGroupPopover',
        components: {
            ManualPopover,
            StayGroupFormMr1,
            ResetAndConfirmButton
        },
        computed: {
            ...mapGetters({
                show : 'mr1/showPopoverGroup',
            }),
        },
        methods : {
            ...mapActions({
                closeInput : 'mr1/closeInput',
                resetGroup:'mr1/stayGroup/reset',
                setDefaultIfNothing : 'mr1/stayGroup/setDefaultIfNothing',
                openInputDates : 'mr1/openInputDates'
            }),
            setDefaultIfNothingAndOpenDate(){
                this.setDefaultIfNothing()
                this.openInputDates()
            },
        }
    }
</script>
