<template>
    <b-modal
        no-fade
        @close="closeInput"
        :visible="isVisible"
        no-close-on-backdrop
        no-close-on-esc
        :title="title"
        :hide-header-close="backNavigation"
        @shown="isShown"
        @hide="isHidden"
    >
        <template v-slot:modal-title>
            <back-button @click="closeInput" v-if="backNavigation"/>
            {{ title }}
        </template>

        <stay-date-and-type-selector v-if="shown"/>

        <template v-slot:modal-footer>
            <reset-and-confirm-button @reset="resetDate" @confirm="setDefaultIfNothingAndCloseInput"/>
        </template>
    </b-modal>
</template>
<script>

    import { mapActions, mapGetters } from 'vuex'
    import { BModal } from 'bootstrap-vue'

    import BackButton from "Components/ui/Modals/BackButton/BackButton";
    import StayDateAndTypeSelector
        from "Components/container/MR1/components/container/StayDateAndTypeSelector/StayDateAndTypeSelector";
    import ResetAndConfirmButton from "Components/ui/form/ResetAndConfirmButton";

    const StayDatesModal = {
        components: {
            BModal,
            BackButton,
            StayDateAndTypeSelector,
            ResetAndConfirmButton
        },
        data(){
            return {
                // Probleme d'affichage avec le calendrier actuel
                // il faut le render uniquement lorsque la modal a fini son animation
                shown : false
            }
        },
        computed: {
            title(){
                return this.$t("Quand voulez-vous partir ?")
            },
            ...mapGetters({
                isVisible : 'modals/stayDatesShow',
                backNavigation : 'mr1/isFormInModal'
            })
        },
        methods :{
            ...mapActions({
                resetDate : 'mr1/stayDateSelected/reset',
                setDefaultStayTypeIfNothing : 'mr1/stayDateSelected/setDefaultStayTypeIfNothing',
                closeInput:'mr1/closeInput'
            }),
            isShown(){
                this.shown = true
            },
            isHidden(){
                this.shown = false
            },
            setDefaultIfNothingAndCloseInput(){
                this.setDefaultStayTypeIfNothing()
                this.closeInput()
            },
        }
    };
    export default StayDatesModal
</script>
