<template>
    <b-form-checkbox
            :name="name"
            v-model="checkboxVal">
        <p v-html="$t('__AEV5_Envoyez-moi les <b>bons plans</b> & <b>nouveautés</b> Ardennes-Etape !')"></p>
    </b-form-checkbox>
</template>

<script>

    import { BFormCheckbox } from 'bootstrap-vue'

    export default {
        name: "RegisterNewsletter",
        components: {
            BFormCheckbox
        },
        props: {
            name: {
                type: String
            },
            value : {
                type : Boolean
            }
        },
        computed: {
            checkboxVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        }
    }
</script>

<style scoped>

</style>
