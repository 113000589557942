<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <b-modal
                        @hide="closeSignUp"
                        ref="signUp"
                        id="signUp"
                        :visible="isVisible"
                        hide-footer
                >
                    <template v-slot:modal-title>
                        <back-button @click="openLogin"/>
                        {{ $t('__AEV5_Je crée un compte gratuit.') }}
                    </template>
                    <sign-up-form/>
                </b-modal>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { BModal, BButton } from 'bootstrap-vue'

    import BackButton from "Components/ui/Modals/BackButton/BackButton";
    import SignUpForm from "Components/ui/SignUpForm";

    export default {
        name: "SignUpModal",
        components: {
            BackButton,
            BModal,
            SignUpForm,
            BButton
        },
        computed: {
            ...mapGetters({
                isVisible : 'modals/signUpShow',
            })
        },
        methods : {
            ...mapActions({
                openLogin : 'modals/openLogin',
                closeSignUp : 'modals/closeSignUp'
            })
        }

    }
</script>

<style scoped>

</style>
