<template>
   <div>
        <div class="row">
            <div class="col-12">
                <facebook-login :href="fbAuthUrl"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <google-login :href="googleAuthUrl"/>
            </div>
        </div>
   </div>
</template>

<script>
    import { mapState} from 'vuex'
    import FacebookLogin from "./components/ui/FacebookLogin";
    import GoogleLogin from "./components/ui/GoogleLogin";

    export default {
        name: "LoginSocialMedia",
        components:{
            GoogleLogin,
            FacebookLogin
        },
        computed: {
            ...mapState('auth', {
                fbAuthUrl: ({socials: {fbAuthUrl}}) => fbAuthUrl,
                googleAuthUrl: ({socials: {googleAuthUrl}}) => googleAuthUrl
            }),
        }
    }
</script>

<style scoped>

</style>
