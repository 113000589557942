<template>
    <div class="demo">
        <div class="autosuggest-container">
            <vue-autosuggest
                    ref="autosuggest"
                    v-model="query"
                    :suggestions="filteredOptions"
                    @click="clickHandler"
                    @selected="onSelected"
                    :should-render-suggestions="() => true"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{id:'autosuggest__input', placeholder: $t('Entrer une localitée')}"
                    :sectionConfigs="sectionConfigs"
                    :renderSuggestion="renderSuggestion"
            >
                <div slot-scope="{suggestion}">
                    <div style="{ display: 'flex', color: 'navyblue'}" v-html="makeQueryBold(suggestion.item)">{{suggestion.item.item.name}}</div>
                </div>
            </vue-autosuggest>
        </div>
    </div>
</template>

<script>
    import Fuse from 'fuse.js'
    import { VueAutosuggest } from "vue-autosuggest";
    import { isIE11 } from 'Utils/browser'

    export default {
        name: "StayLocalitiesSuggest",
        components: {
            VueAutosuggest
        },
        data() {
            return {
                query: "",
                selected: "",
                sectionConfigs: {
                  topDestinations: {
                    label: this.$t("__AEV5_Top destination"),
                    onSelected: this.onSelected
                  },
                  other: {
                    label: this.$t("__AEV5_Autres villes"),
                    onSelected: this.onSelected
                  },
                  default: {
                    // Only here to avoid bug when on click on the title (top destination, ..)
                    onSelected: this.onSelected
                  }
                }
            };
        },
        props:{
            suggestions : {
                type: Array,
                required: true
            }
        },
        computed: {
            suggestionsForFuse(){
                return this.suggestions.map(locality => ({
                    id : locality.id,
                    name : locality.name,
                    sanitizedName : this.removeAccent(locality.name),
                    topDestination : locality.topDestination
                }))
            },
            allSuggestionsAsFuseResult(){
                return this.suggestionsForFuse.map(val => ({
                    item: Object.assign(val, {}),
                    matches: [],
                    score: 1
                })).sort((a,b) => a.item.name.localeCompare(b.item.name));
            },
            fuseResult(){
                if(this.query === '')
                    return this.allSuggestionsAsFuseResult

                const options = {
                    shouldSort: true,
                    includeMatches: true,
                    threshold: 0.2,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name",
                        "sanitizedName"
                    ]
                };

                const fuse = new Fuse(this.suggestionsForFuse, options); // "list" is the item array
                return fuse.search(this.query);
            },
            filteredOptions() {
                const data = this.fuseResult

                const topDestinations = data.filter(({item}) => item.topDestination === true);
                const otherDestinations = data.filter(({item}) => item.topDestination === false);

                return [
                  {name : 'topDestinations', data : topDestinations},
                  {name : 'other', data : otherDestinations}
                ];
            }
        },
        methods: {
            flatString(string){
                return this.removeAccent(string.toLowerCase())
            },
            makeQueryBold(suggestion) {
                return suggestion.item.name;
            },
            doesItMatchQuery(string){
                return string.indexOf(this.query.toLowerCase()) > -1;
            },
            removeAccent(string){
                return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            },
            clickHandler(item) {
                // event fired when clicking on the input
            },
            onSelected(item) {
                if(item){
                    this.$emit("selected",item.item.item.id)
                    this.selected = item.item;
                }
            },
            /**
             * This is what the <input/> value is set to when you are selecting a suggestion.
             */
            getSuggestionValue(suggestion) {
                return suggestion.item.name;
            },
            renderSuggestion(suggestion) {
                return suggestion.item.item.name;
            },
        },
        mounted(){
            // sur IE 11, le focus casse la sélection des élements
            if(!isIE11()){
                this.$refs.autosuggest.$el.querySelector("input").focus();
            }

        }
    }
</script>

<style>

</style>
