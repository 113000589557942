<template>
    <b-button
            variant="link"
            @click="$emit('click')">
        {{ $t('Partout en Ardenne') }}
    </b-button>
</template>

<script>
    import {BButton} from 'bootstrap-vue'
    export default {
        name: "NoPreferenceButton",
        components :{
            BButton
        }
    }
</script>
