<template>
    <input-button
            id="stay-date-button"
            :showRemove="isCompleted"
            v-on="$listeners"
    >
        {{ txt }}
    </input-button>
</template>

<script>
    import InputButton from "../InputButton";
    import { getDateTextFromStayDate } from "Utils/textFormat/stay"

    export default {
        name: "StayDateButton",
        components : {
            InputButton
        },
        props: {
            stayDate : {
                type: Object,
                required : true
            },
            isCompleted : {
                type : Boolean,
                required : true
            }
        },
        computed: {
            txt(){
                return getDateTextFromStayDate(this.stayDate)
            }
        }
    }
</script>

<style scoped>

</style>
