<template>
    <b-form-group
            :label="$t('__AEV5_Votre adresse email')"
            :state="state"
            :invalid-feedback="invalidFeedback">
        <b-form-input
                type="email"
                :autofocus="autofocus"
                :name="name"
                v-model="inputValue"
                :state="state"
                required />
    </b-form-group>
</template>

<script>
    import { BFormInput, BFormGroup } from 'bootstrap-vue'
    import InputDefaultBehavior from "Mixins/InputDefaultBehavior";
    import {isValidEmail} from "Utils/validator";

    export default {
        name: "EmailInput",
        components:{
            BFormInput,
            BFormGroup
        },
        props:{
            autofocus : {
                type: Boolean,
                default: false
            }
        },
        mixins: [InputDefaultBehavior],
        computed: {
            state() {
                return this.inputValue === null
                    ? null
                    : isValidEmail(this.inputValue)
            },
            invalidFeedback(){
                return this.$t("__AEV5_Email non valide")
            }
        }
    }
</script>

<style scoped>

</style>
