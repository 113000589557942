<template>
    <div v-bind:class="`${customClass} container sameDateSelector`">
        <div class="close" @click="cancel"></div>
        <div class="row">
            <div class="col-12">
                <b-form-group :label="$t('Choisissez un séjour parmi ceux ci-dessous :')">
                    <b-form-radio
                            v-for="(stayDate, index) in stayDates"
                            :key="index"
                            v-on="$listeners"
                            :value="index"
                            v-model="selected">
                        {{ stayLabel(stayDate) }}
                        <span v-if="stayDate.description">{{ $t(stayDate.description) }}</span>
                    </b-form-radio>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import days from 'dayjs'
    import { BFormGroup, BFormRadio } from 'bootstrap-vue'

    export default {
        name: "SameStartDateStaySelector",
        data() {
            return {
                selected: null
            }
        },
        components : {
            BFormGroup,
            BFormRadio
        },
        props:{
            stayDates : {
                type: Array,
                required : true
            },
            customClass : {
                type: String,
                required: false
            }
        },
        methods:{
            cancel(){
                this.$emit("cancel")
            },
            stayLabel(stay){
                return this.$t("Du {startDate} au {endDate}", {
                    startDate : days(stay.start).format('ddd D MMM'),
                    endDate : days(stay.end).format('ddd D MMM')
                })
            }
        }
    }
</script>

<style scoped>

</style>
