<template>
    <div class='error-wrapper'>
        <b-toast
                :visible="true"
                v-for='(toast, index) in toasts'
                :key='index'
                append-toast
                @hidden="hidden(toast.id)"
                toaster="b-toaster-bottom-center"
                :variant="toast.variant"
        >
           <span v-html="toast.message"></span>
        </b-toast>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import {BToast} from 'bootstrap-vue'

    export default {
        name: 'Toaster',
        components:{
            BToast
        },
        methods: {
            ...mapActions({
                removeToast : 'toaster/removeToast',
            }),
            hidden(id) {
                console.log(id);
                this.removeToast(id);
            }
        },
        computed: {
            ...mapState('toaster', {
                toasts : ({toasts}) => toasts
            }),
        }
    }
</script>

<style>


</style>
