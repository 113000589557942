<template>
    <b-button v-on="$listeners" class="buttonLeft"/>
</template>

<script>
    import { BButton } from "bootstrap-vue"
    export default {
        name: "BackButton",
        components : {
            BButton
        }
    }
</script>
