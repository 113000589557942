<template>
    <b-button
            variant="link"
            v-on="$listeners">{{ $t('__AEV5_Mot de passe oublié ?' )}}</b-button>
</template>

<script>
    import { BButton } from 'bootstrap-vue'

    export default {
        name: "ForgotPasswordButton",
        components : {
            BButton
        }
    }
</script>

<style scoped>

</style>
