<template>
    <b-button-group v-on="$listeners">
        <b-button
                ref="button"
                variant="light-inlineMobile">
            {{ txt }}
        </b-button>
    </b-button-group>
</template>

<script>

    import { BButton, BButtonGroup } from 'bootstrap-vue'
    import {getDateTextFromStayDate, getStayGroupTxt} from "Utils/textFormat/stay";

    export default {
        name: "SummaryButton",
        components : {
            BButton,
            BButtonGroup
        },
        props: {
            stayDate : {
                type: Object
            },
            personCount : {
                type: Number
            },
            dogCount : {
                type: Number
            },
            localityName : {
                type: String
            }
        },
        computed: {
            txt(){
                return `${this.groupTxt}, ${this.dateTxt.toLowerCase()}, ${this.localityTxt}`
            },
            dateTxt(){
                return getDateTextFromStayDate(this.stayDate)
            },
            localityTxt(){
                return this.localityName || this.$t('en Ardenne')
            },
            groupTxt(){
                if(!this.personCount){
                   return `2-60 pers.`
                }
                return getStayGroupTxt({
                    personCount : this.personCount,
                    dogCount : this.dogCount
                }, false)
            }
        }
    }
</script>
