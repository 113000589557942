<template>
    <b-modal
            no-fade
            @cancel="closeModal"
            @close="closeModal"
            @hide="closeModal"
            :visible="isVisible"
            :title="$t('__AEV5_Chercher un séjour en Ardenne')"
            no-close-on-backdrop
            hide-footer
    >
        <form-MR1/>
    </b-modal>
</template>
<script>

    import {mapActions, mapGetters} from 'vuex'
    import { BModal } from 'bootstrap-vue'
    import FormMR1 from "Components/container/MR1/components/container/FormMR1";

    export default {
        name : 'FormMR1Modal',
        components: {
            FormMR1,
            BModal,
        },

        computed: {
            ...mapGetters({
                isVisible : 'modals/mr1FormModalShow',
            })
        },
        methods :{
            ...mapActions({
                closeModal:'mr1/closeModal'
            }),
        }
    }
</script>
