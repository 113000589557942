<template>
    <input-button
            id="stay-locality-button"
            :showRemove="localityName !== null"
            v-on="$listeners"
    >
        {{ placeHolder }}
    </input-button>
</template>

<script>
    import InputButton from "../InputButton";

    export default {
        name: "StayLocalityButton",
        components : {
            InputButton
        },
        props: {
            hasCompleted : {
                type : Boolean,
                required: true
            },
            localityName : {
                type: String
            },
            noPreference : {
                type : Boolean,
                required: true
            }
        },
        computed: {
            placeHolder(){
                if(this.noPreference || !this.hasCompleted)
                    return this.$t("Partout en Ardenne")
                return this.localityName
            }
        }
    }
</script>

<style scoped>

</style>
