<template>
    <div class="row">
        <div class="col-12">
            <div class="no-compte">
                {{ $t("__AEV5_Vous n'avez pas de compte ?") }}
                <b-button variant="link" v-on="$listeners">{{ $t("__AEV5_Inscrivez-vous ?") }}</b-button>
            </div>
        </div>
    </div>
</template>

<script>

    import { BButton } from 'bootstrap-vue'

    export default {
        name: "SignUpButton",
        components:{
            BButton
        }
    }
</script>

<style scoped>

</style>
