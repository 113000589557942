<template>
    <summary-button
            :dogCount="dogCount"
            :personCount="personCount"
            :stayDate="stayDateSelected"
            :localityName="selectedLocality ? selectedLocality.name : null"
            @click="openModal"/>
</template>

<script>
    import { mapState, mapGetters, mapActions } from "vuex"
    import SummaryButton from "./components/ui/SummaryButton";

    export default {
        name: "OpenFormInModalButton",
        components: {
            SummaryButton
        },
        computed:{
            ...mapState('mr1', {
                personCount: ({stayGroup: {personCount}}) => (personCount),
                dogCount: ({stayGroup: {dogCount}}) => (dogCount),
            }),
            ...mapGetters({
                selectedLocality : 'mr1/stayLocalities/selectedLocality',
                noPreferenceLocality : 'mr1/stayLocalities/noPreference',
                stayDateSelected : 'mr1/stayDateSelected/stayDateSelectedWithDefaultType',
            })
        },
        methods:{
            ...mapActions("mr1",{
                'openModal' : 'openModal'
            })
        }
    }
</script>
