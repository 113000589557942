import Vue from "vue"

import BaseBind from "Utils/BaseBindClass"

export default class Footer extends BaseBind{
    constructor({el, store, i18n}){
        super({el, store, i18n}).init()

        //document.getElementById("infoLegales").addEventListener( 'click', ()=> {this.togglePlusInfoFooter("infoLegalesComplete")} , false );

    }

    togglePlusInfoFooter(field) {
        let el = document.getElementById(field);
        if ( el.classList.contains('show') ) {
            el.classList.remove('show');
        }else{
            el.classList.add('show');
        }
    };


}
