import dayjs from 'dayjs'
import store from "Store/store"
import i18n from "Plugins/i18n"

function genMr1Url({personCount, dogCount, stayType, folderId, startDate, endDate, monthDate, filtersMR2}){

    let url = `/${i18n.t('__SEO_recherche')}/`

    url = addPerson(url, personCount)
    url = addStayType(url, stayType)
    url = addDate(url, {startDate, endDate, monthDate})
    url = addFolder(url, folderId)
    url = addDog(url, dogCount)
    url = addFilterMr2(url, removeDogFilterIfNecessary(filtersMR2, dogCount))

    return url
}

function addPerson(url, personCount){
    if(null === personCount || personCount == 0) {
        personCount = '1-99';
    }
    return `${url}${personCount}p/`;
}

function addStayType(url, stayType){
    const stayTypes = {
        'wk': i18n.t('__AEV5_type sejour_wk'),
        'lw': i18n.t('__AEV5_type sejour_lw'),
        'mw': i18n.t('__AEV5_type sejour_mw'),
        '1w': i18n.t('__AEV5_type sejour_1w'),
        '2w': i18n.t('__AEV5_type sejour_2w')
    };
    if(null === stayType || null === stayTypes[stayType]) {
        stayType = 'wk';
    }
    return `${url}${stayTypes[stayType].split(' ').join('-')}/`;
}

function addDate(url, {startDate, endDate, monthDate}) {
    if(null === startDate || startDate == '') {
        if(monthDate){
            return `${url}${dayjs(monthDate).format('YYYY-MM')}-00/`
        }
        return `${url}0000-00-00/`
    } else {
        return `${url}${startDate}${endDate ? `_to_${endDate}` : ''}/`
    }
}
function addFolder(url, folderId){
    if(folderId > 0) {
        return `${url}${folderId}_${i18n.t('__SEO_RECHERCHE_Dossier')}/`;
    }
    return url
}

function addDog(url, dogCount){
    if(dogCount > 0) {
        return `${url}${dogCount}-8_${i18n.t('__SEO_RECHERCHE_Animaux')}/`;
    }
    return url
}
function removeDogFilterIfNecessary(filtersMR2, dogCountMR1){
    if(dogCountMR1 > 0) {
        return filtersMR2.filter(filterMR2 => !isDogFilter(filterMR2));
    }
    return filtersMR2
}
function addFilterMr2(url, filtersMR2){
    return `${url}${filtersMR2.join('/')}`
}

function isDogFilter(filter){
    const reg = new RegExp(i18n.t('__SEO_RECHERCHE_Animaux'));
    return reg.test(filter)
}

const launchResearch = () => {
    const params = store.getters.paramsForResearch
    window.location.href = genMr1Url(params)
}
export default launchResearch
