<template>
    <b-button class="reset" variant="link" @click="$emit('click')">{{ $t('Effacer') }}</b-button>
</template>

<script>
    import { BButton } from 'bootstrap-vue'
    export default {
        name: "ResetButton",
        components :{
            BButton
        }
    }
</script>
