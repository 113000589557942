<template>
    <b-button
            :title="$t('__AEV5_Mr1Button_Title')"
            data-gacategory="Header"
            data-gaaction="Bouton"
            data-galabel="Trouver"
            variant="secondary"
            type="submit">
        {{ $t("__aev5_TrouverMr1") }}
    </b-button>
</template>

<script>
    import { BButton } from 'bootstrap-vue'

    export default {
        name: "SearchButton",
        components : {
            BButton
        }
    }
</script>
