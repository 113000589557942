import { getCapacityLabel, getstayTypeAsTextFromShortStayType, getTodayDate, getNbDaysBetweenToDates, getNbDaysToDate, getMonthAndyear } from "Plugins/dataLayer/dataLayerUtils";
import { addContextualDataLayer, pushContextualDataLayer } from "Plugins/dataLayer/dataLayerHelper";
import stayDateSelected from "../../store/mr1/stayDateSelected";

export default class datalayerMR1 {

    constructor(store) {
        this.store = store
        this.pageType = this.store.state.page.page
        this.init()
    }
    init() {
        this.initWithCurrent(this.store.state);
        this.watchStore();
    }
    initWithCurrent(state) {
        let currentContextualDatalayer = getContextualDataLayer("Home", false)
        if(! currentContextualDatalayer.booking_number_people) {
            this.stayGroupChange(state.mr1.stayGroup)
            this.stayLocalitiesChange(state.mr1.stayLocalities)
            this.stayDateSelectedChange(state.mr1.stayDateSelected)
        }
    }
    watchStore() {
        // Groupe
        this.store.watch(
            state => state.mr1.stayGroup,
            newStayGroup => this.stayGroupChange(newStayGroup),
            {deep : true})
        // Date & type séjour
        this.store.watch(
            state => state.mr1.stayDateSelected,
            newStayDateSelected => this.stayDateSelectedChange(newStayDateSelected),
            {deep : true})
        // Localité
        this.store.watch(
            state => state.mr1.stayLocalities,
            newStayLocalities => this.stayLocalitiesChange(newStayLocalities),
            {deep : true})
    }
    stayGroupChange(stayGroup) {
        addContextualDataLayer("booking_number_people", stayGroup.personCount)
        setItemToGA4DataLayerInLocalStorage("filter_booking_people_number", stayGroup.personCount)

        addContextualDataLayer("booking_capacity", getCapacityLabel(stayGroup.personCount))
        setItemToGA4DataLayerInLocalStorage("filter_booking_capacity", getCapacityLabel(stayGroup.personCount))

        addContextualDataLayer("booking_dog", stayGroup.dogCount ? stayGroup.dogCount : 0)
        setItemToGA4DataLayerInLocalStorage("filter_booking_dog_number", stayGroup.dogCount ? stayGroup.dogCount : 0)

        pushContextualDataLayer(this.pageType)
    }
    stayDateSelectedChange(stayDateSelected) {
        const startDate = stayDateSelected.start;
        const endDate   = stayDateSelected.end

        addContextualDataLayer("booking_outbound_date", startDate)
        setItemToGA4DataLayerInLocalStorage("filter_booking_arrival_date", startDate)

        addContextualDataLayer("booking_inbound_date", endDate)
        setItemToGA4DataLayerInLocalStorage("filter_booking_leaving_date", endDate)

        addContextualDataLayer("booking_booking_date", getTodayDate())
        setItemToGA4DataLayerInLocalStorage("filter_booking_date", getTodayDate())

        addContextualDataLayer("booking_length_of_stay", getNbDaysBetweenToDates(startDate, endDate))
        setItemToGA4DataLayerInLocalStorage("filter_booking_length_of_stay", getNbDaysBetweenToDates(startDate, endDate))

        addContextualDataLayer("booking_horizon", getNbDaysToDate(startDate))
        setItemToGA4DataLayerInLocalStorage("filter_booking_booking_window", getNbDaysToDate(startDate))

        addContextualDataLayer("booking_period", getMonthAndyear(startDate))
        setItemToGA4DataLayerInLocalStorage("filter_booking_period", getMonthAndyear(startDate))

        addContextualDataLayer("booking_holiday_break", getstayTypeAsTextFromShortStayType(stayDateSelected.type))
        setItemToGA4DataLayerInLocalStorage("filter_booking_stay_type", getstayTypeAsTextFromShortStayType(stayDateSelected.type))

        pushContextualDataLayer(this.pageType)
    }
    stayLocalitiesChange(stayLocalities) {
        const locationName = stayLocalities.selectedLocalityId
            ? stayLocalities.data[stayLocalities.selectedLocalityId].name
            : "everywhere in Ardennes"

        addContextualDataLayer("booking_location", locationName)
        setItemToGA4DataLayerInLocalStorage("filter_booking_location", locationName)
        pushContextualDataLayer(this.pageType)
    }
}