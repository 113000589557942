import { render, staticRenderFns } from "./SignUpButton.vue?vue&type=template&id=cbe13316&scoped=true&"
import script from "./SignUpButton.vue?vue&type=script&lang=js&"
export * from "./SignUpButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe13316",
  null
  
)

export default component.exports