<template>
    <b-form @submit="onSubmit" method="post" :id="id">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <name-input v-model="name" ref="name" name="prenomInscr" :autofocus="true"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <last-name-input v-model="lastName" ref="lastName" name="nomInscr" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <email-input v-model="email" ref="email" name="emailInscr"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <password-input v-model="password" ref="password" name="mdpInscr"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <register-newsletter v-model="registerNewsletter" name="InscriptionNewsletter"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <remember-me v-model="stayConnected" name="resterConnecte"/>
                </div>
            </div>
            <token-input/>
            <recaptcha/>
            <continue-submit-button></continue-submit-button>
        </div>
    </b-form>
</template>

<script>

    import { BForm, BButton } from 'bootstrap-vue'

    import NameInput from "Components/ui/form/NameInput";
    import LastNameInput from "Components/ui/form/LastNameInput";
    import EmailInput from "Components/ui/form/EmailInput";
    import PasswordInput from "Components/ui/form/PasswordInput";
    import RememberMe from "Components/ui/form/RememberMe";
    import RegisterNewsletter from "Components/ui/form/RegisterNewsletter";
    import Recaptcha from "Components/container/Recaptcha";

    import TokenInput from "Components/container/TokenInput";
    import ContinueSubmitButton from "Components/ui/form/ContinueSubmitButton";

    export default {
        name: "SignUpForm",
        components:{
            Recaptcha,
            BForm,
            BButton,
            NameInput,
            LastNameInput,
            EmailInput,
            PasswordInput,
            RememberMe,
            RegisterNewsletter,
            ContinueSubmitButton,
            TokenInput
        },
        props : {
            id : {
              type: String
            }
        },
        data(){
            return {
                email: null,
                password: null,
                name: null,
                lastName: null,
                stayConnected: false,
                registerNewsletter: false
            }
        },
        computed:{
            isFormValid(){
                return this.$refs.email.state
                    && this.$refs.password.state
                    && this.$refs.name.state
                    && this.$refs.lastName.state
            },
        },
        methods: {
            invalidInput(inputName){
                if(this.$refs[inputName].state && this[inputName] === null){
                    this[inputName] = ''
                }
            },
            invalidForm(){
                this.invalidInput('email');
                this.invalidInput('password');
                this.invalidInput('name');
                this.invalidInput('lastName');
            },
            onSubmit(e) {
                if(!this.isFormValid) {
                    e.preventDefault();
                    this.invalidForm();
                }
            },
        },
    }
</script>
