import Vue from "vue"
import lazyLoad from "Utils/lazyLoad";
import BaseBind from "Utils/BaseBindClass"
import LoginModal from "Components/container/LoginModal";
import SignUpModal from "Components/container/SignUpModal";
import ForgotPasswordModal from "Components/container/ForgotPasswordModal";
//import Header from "Classes/container/Header";
import Footer from "Classes/container/Footer";
import Toaster from "Components/container/Toaster/Toaster";
import CookieBar from "Classes/container/CookieBar";
import FullPageSpinner from "Components/container/FullPageSpinner";
import {setCurSearchNumberOnFocus, setSearchNumberCookie }from "Utils/search/searchNumberSetter";
import GenericModal from "Components/container/GenericModal/GenericModal";
/**
 * Class reprenant le code exécuté sur toutes les pages
 */
export default class Global extends BaseBind{
    constructor({ store, i18n, data }){
        super({ store, i18n }).init()

        this.data = data

        //this.initHeader()
        this.initFooter()
        this.updateLazyLoad()
        this.initForgotPasswordModal()
        this.initLoginModal()
        this.initSignUpModal()
        this.initToaster()
        this.initCookieBar()
        this.initSejourUrl()
        this.initFullPageSpinner()
        setSearchNumberCookie()
        setCurSearchNumberOnFocus()

        this.createGenericModal();
        this.openGenericModalIfNeeded(data)
    }
    initHeader(){
        new Header({
            i18n : this.i18n,
            store : this.store,
            data : this.data
        });
    }
    initFooter(){
        new Footer({
            i18n : this.i18n,
            store : this.store,
            data : this.data
        });
    }
    initSignUpModal(){
        new Vue({
            el: '#signUpModal',
            store : this.store,
            i18n : this.i18n,
            render(createElement) {
                return createElement(SignUpModal);
            },
            components: { SignUpModal }
        });
    }
    initLoginModal(){

        const { encodedEmail = null, errorMsg = null } = this.data["LoginModal"] || {};

        new Vue({
            el: '#loginModal',
            store : this.store,
            i18n : this.i18n,
            render(createElement) {
                return createElement(LoginModal, {
                    props: {
                        encodedEmail,
                        errorMsg
                    }
                });
            },
            components: { LoginModal }
        });
    }
    initForgotPasswordModal(){
        new Vue({
            el: '#forgotPasswordModal',
            store : this.store,
            i18n : this.i18n,
            render(createElement) {
                return createElement(ForgotPasswordModal);
            },
            components: { ForgotPasswordModal }
        });
    }
    updateLazyLoad(){
        lazyLoad.update();
    }
    initToaster(){
        new Vue({
            el: '#toaster',
            store : this.store,
            render(createElement) {
                return createElement(Toaster);
            },
            components: { Toaster }
        });
    }

    initCookieBar(){
        if(document.getElementById("cookiesTopBar")){
            new CookieBar({
                el: "#cookiesTopBar",
            })
        }

    }

    initSejourUrl(){
        const elements = document.querySelectorAll(".sejourUrl");
        elements.forEach(el => el.setAttribute('href', el.dataset.urlsejour))
    }

    initFullPageSpinner(){
        new Vue({
            el: '#fullPageSpinner',
            store : this.store,
            render(createElement) {
                return createElement(FullPageSpinner);
            },
            components: { FullPageSpinner }
        });
    }

    createGenericModal(){

        new Vue({
            el: '#genericModal',
            store : this.store,
            render(createElement) {
                return createElement(
                    GenericModal
                );

            },
            components: { GenericModal }
        });


    }
    openGenericModalIfNeeded({generic_modal_content, generic_modal_title}){
        if(generic_modal_content){
            this.store.dispatch("modals/openGeneric",generic_modal_content, generic_modal_title);
        }
    }
}
