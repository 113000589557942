<template>
    <b-button variant="google" :href="href">{{ $t('__Continuer avec Google') }}</b-button>
</template>

<script>
    import { BButton } from 'bootstrap-vue'
    export default {
        name: "GoogleLogin",
        components : {
            BButton
        },
        props:{
            href:{
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
