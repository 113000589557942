<template>
    <div>

        <open-form-in-modal-button v-if="isFormInModal"/>

        <form-MR1 v-else/>
        <form-MR1-modal/>

        <stay-dates-modal/>
        <stay-group-modal/>
        <stay-localities-modal/>

        <stay-group-popover/>
        <stay-localities-popover/>
        <stay-dates-popover/>

    </div>
</template>

<script>


    import {  mapGetters  } from "vuex"

    import OpenFormInModalButton from "./components/container/OpenFormInModalButton";
    import FormMR1 from "./components/container/FormMR1";
    import FormMR1Modal from "./components/container/FormMR1Modal";

    import StayLocalitiesPopover from "./components/container/StayLocalitiesPopover"
    import StayDatesPopover from "./components/container/StayDatesPopover"
    import StayGroupPopover from "./components/container/StayGroupPopover"

    import StayLocalitiesModal from "./components/container/StayLocalitiesModal"
    import StayDatesModal from "./components/container/StayDatesModal"
    import StayGroupModal from "./components/container/StayGroupModal"

    const MR1 = {
        name: 'MR1',
        components: {
            OpenFormInModalButton,
            FormMR1,
            FormMR1Modal,

            StayLocalitiesPopover,
            StayDatesPopover,
            StayGroupPopover,

            StayLocalitiesModal,
            StayDatesModal,
            StayGroupModal
        },
        computed : {
            ...mapGetters({
                isFormInModal : 'mr1/isFormInModal',
            }),
        }
    }

    export default MR1
</script>
