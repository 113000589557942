<template>
    <Spinner v-if="isLoading"/>
    <section v-else-if="error">
        Une erreur est survenue, essayer de rafraichir la page.
    </section>
    <section v-else>
        <div class="containerMr1">
            <div class="row btnMr1Date">
                    <div class="col-12 col-md-8">
                        <stay-type-selector
                                :asSelect="isMobile"
                                :stayTypes="stayTypes"
                                :stayTypeSelected="stayType"
                                @change="onChangeStayType"
                        />
                        </div>
                <div class="col-12 col-md-4">
                    <stay-month-selector/>
                </div>
            </div>
        </div>
        <stay-dates-calendar
            :dateToShow="dateToShow"
            :stayDates="stayDates"
            :calendarsCount="calendarsCount"
            @changeDateToShow="onChangeDateToShow"
            @clickDay="clickDay"
            :highlightedDates="stayDateSelected"
            @selectMonth="onMonthSelection"
        />
        <same-start-date-stay-selector
                :customClass="sameStartDateStayToSelectClass"
                @change="onSelectStaySameStartDate"
                @cancel="unsetSameStartDateStayToSelect"
                v-if="showSameStartDateStay"
                :stayDates="sameStartDateStayToSelect"/>
    </section>
</template>

<script>

    import dayjs from 'dayjs'
    import { mapActions, mapState, mapGetters } from "vuex"
    import { isSameDay, isSameMonth, stringYMDToDate } from "Utils/date"
    import StayDatesCalendar from "Components/ui/StayDatesCalendar"
    import SameStartDateStaySelector from "./components/ui/SameStartDateStaySelector"
    import Spinner from "Components/ui/Spinner";
    import StayMonthSelector from "Components/container/MR1/components/container/StayMonthSelector";
    import StayTypeSelector from "Components/ui/StayTypeSelector";

    export default {
        name: "StayDateSelector",
        components:{
            StayDatesCalendar,
            SameStartDateStaySelector,
            Spinner,
            StayMonthSelector,
            StayTypeSelector
        },
        data() {
            return {
                sameStartDateStayToSelect : null,
                marked : {start : false, end: false}
            }
        },
        props:{
          stayType:{
              required:true,
              type: String
          },
          stayTypes:{
              required:true,
              type : Array
          }
        },
        computed:{
            ...mapGetters('breakpoint',{
               isMobile : 'isMobile'
            }),
            ...mapGetters('mr1',{
                dateToShow : 'showDate/showDate',
            }),
            ...mapState('mr1',{
                error : ({ stayDates : { error }}) => (error),
                isLoading : ({ stayDates : { isLoading }}) => (isLoading),
                stayDateSelected : ({ stayDateSelected : { start, end, month }}) => ({start, end, month}),
                stayTypeSelected : ({ stayDateSelected : { type }}) => type,
            }),
            stayDates(){
                return this.$store.getters['mr1/stayDates/staysByType'](this.stayType)
            },
            calendarsCount(){
                return this.isMobile ? 1 : 2
            },
            sameStartDateStayToSelectClass(){
                if(this.sameStartDateStayToSelect){
                    const pos = this.getDateIndexOfCalendarsShowed(this.sameStartDateStayToSelect[0].start)
                    if(pos !== false){
                        return pos === 1 ? "left" : "right"
                    }
                }
                return '';
            },
            showSameStartDateStay(){
                return this.sameStartDateStayToSelect
                    ? this.isDateInScopeOfCalendarsShowed(this.sameStartDateStayToSelect[0].start)
                    : false
            },
        },
        methods:{
            ...mapActions({
                setShowDate : 'mr1/showDate/setDate',
                getStayDates : 'mr1/stayDates/get',
            }),
            getStaysByStartDate(date){
                return this.stayDates.filter(({start}) => (isSameDay(stringYMDToDate(start), date)))
            },
            clickDay(date){
                const staysSameStartDate = this.getStaysByStartDate(date)

                if(staysSameStartDate.length === 0)
                    return;

                if(staysSameStartDate.length === 1){
                    this.onStaySelected(staysSameStartDate[0])
                    return;
                }

                this.setSameStartDateStayToSelect(staysSameStartDate)
            },
            setSameStartDateStayToSelect(staysSameStartDate){
                this.sameStartDateStayToSelect = staysSameStartDate
            },
            unsetSameStartDateStayToSelect(){
                this.sameStartDateStayToSelect = null
            },
            onChangeDateToShow(date){
                this.setShowDate(dayjs(date).format("YYYY-MM-DD"))
            },
            onSelectStaySameStartDate(index){
                if(!this.sameStartDateStayToSelect || !this.sameStartDateStayToSelect[index]){
                    console.warn(`Aucun séjour avec même date de départ trouvé pour l'index ${index}`)
                    return;
                }
                this.onStaySelected(this.sameStartDateStayToSelect[index])
            },
            onStaySelected(stay){
                this.unsetSameStartDateStayToSelect()
                this.$emit("change",stay)
            },
            onChangeStayType(type){
                this.$emit("changeStayType",type)
            },
            // On check si on est dans le scope de dateToShow
            // Regle fixe a 1 ou 2 mois pour le moment..
            getDateIndexOfCalendarsShowed(date){
                const dateToShowDate = new Date(this.dateToShow)
                date = new Date(date)
                if(isSameMonth(dateToShowDate, date)){
                    return 1
                }
                if(this.calendarsCount === 2){
                    const dateToShowPlusOneMonth = dayjs(dateToShowDate).add(1, 'month')
                    if(isSameMonth(dateToShowPlusOneMonth.toDate(), date)){
                        return 2;
                    }
                }
                return false;
            },
            isDateInScopeOfCalendarsShowed(date){
                return this.getDateIndexOfCalendarsShowed(date) !== false
            },
            onMonthSelection(monthDate){
                this.$emit("onClickMonth",dayjs(monthDate).format("YYYY-MM-DD"))
            }
        },
        mounted(){
            this.getStayDates()
        },
        watch:{
            stayTypeSelected(){
                this.unsetSameStartDateStayToSelect()
            }
        }
    }
</script>

<style scoped>

</style>
