<template>
    <b-modal
            @hide="closeModal"
            :visible="isVisible"
            hide-footer
    >
        <template v-slot:modal-title>
            <back-button @click="openLogin"/>
            {{ $t('__AEV5_Mot de passe oublié ?') }}
        </template>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <b-alert :show="errorMsg" variant="warning">{{ errorMsg }}</b-alert>

                    <b-spinner v-if="loading"/>

                    <div v-else-if="emailSent">
                        {{ $t('__AEV5_Si le compte existe, un email vous a été envoyé.') }}
                    </div>
                    <forgot-password-form
                            v-else
                            @onValidSubmit="onValidSubmit"/>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import { prepareErrorMessage } from "API/helpers";
    import { askNewPassword } from 'API'
    import { mapGetters, mapActions } from 'vuex'
    import { BModal, BAlert, BSpinner, BButton} from 'bootstrap-vue'
    import ForgotPasswordForm from "./components/ui/ForgotPasswordForm";
    import BackButton from "Components/ui/Modals/BackButton/BackButton";

    export default {
        name: "ForgotPasswordModal",
        components: {
            BackButton,
            BModal,
            ForgotPasswordForm,
            BAlert,
            BSpinner,
            BButton
        },
        data(){
            return {
                emailSent : false,
                errorMsg : null,
                loading : false
            }
        },
        computed: {
            ...mapGetters({
                isVisible : 'modals/forgotPasswordShow',
            })
        },
        methods : {
            ...mapActions({
                openLogin : 'modals/openLogin',
                closeModal : 'modals/closeForgotPassword',
                askNewPassword : 'auth/askNewPassword',
                addError : 'errors/addError'
            }),
            async onValidSubmit(data){
                if(this.loading)
                    return;

                this.loading = true
                try {
                    const response = await askNewPassword(data);
                    this.handleResponse(response.data)
                } catch ({errorStatus}) {
                    this.addError(prepareErrorMessage(errorStatus));
                }
                this.loading = false
            },
            handleResponse({status, message : errorMessage}){
                if(status === 0){
                    this.errorMsg = errorMessage
                }else{
                    this.emailSent = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>
