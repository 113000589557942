<template>
    <b-form @submit="onSubmit" method="post" id="formForgot">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p>{{ $t('__AEV5_Mot de passe oublié ? Demandez-en un nouveau :') }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <email-input v-model="email" ref="email" :autofocus="true"></email-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12 ">
                    <b-button
                            type="submit"
                            variant="success"
                            class="float-right">
                        {{ $t('__AEV5_Demander un nouveau mot de passe') }}</b-button>
                </div>
            </div>
        </div>
        <token-input/>
    </b-form>
</template>

<script>
    import { BButton, BForm } from 'bootstrap-vue'
    import EmailInput from "Components/ui/form/EmailInput";
    import TokenInput from "Components/container/TokenInput";

    export default {
        name: "ForgotPasswordForm",
        components:{
            TokenInput,
            BForm,
            BButton,
            EmailInput
        },
        data(){
            return {
                email : null
            }
        },
        computed: {
            isFormValid(){
                return this.$refs.email.state
            },
        },
        methods:{
            invalidInput(inputName){
                if(this.$refs[inputName].state === null && this[inputName] === null){
                    this[inputName] = ''
                }
            },
            invalidForm(){
                this.invalidInput('email');
            },
            onSubmit (e) {
                e.preventDefault()
                if(!this.isFormValid) {
                    this.invalidForm();
                    return;
                }
                this.$emit("onValidSubmit", {email : this.email})
            }
        }
    }
</script>

<style scoped>

</style>
