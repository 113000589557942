<template>
    <stay-group-form
       :personCountMax="personCountMax"
       :dogCountMax="dogCountMax"
       :personCount="personCountSavedOrDefault"
       :dogCount="dogCountSavedOrDefault"
       @personCountChange="setPersonCount"
       @dogCountChange="setDogCount"
    />
</template>

<script>

    import { mapGetters, mapActions, mapState } from 'vuex'
    import StayGroupForm from 'Components/ui/StayGroupForm'

    export default {
        name: "StayGroupFormMr1",
        components: {
          StayGroupForm
        },
        computed: {
            ...mapState('mr1',{
                personCountMax : ({stayGroup : {personCountMax}}) => (personCountMax),
                dogCountMax : ({stayGroup : {dogCountMax}}) => (dogCountMax)
            }),
            ...mapGetters({
                personCountSavedOrDefault : 'mr1/stayGroup/personCountOrDefault',
                dogCountSavedOrDefault : 'mr1/stayGroup/dogCountOrDefault',
            }),
        },
        methods :{
            ...mapActions({
                setPersonCount:'mr1/stayGroup/setPersonCount',
                setDogCount:'mr1/stayGroup/setDogCount',
                setGroup : 'mr1/stayGroup/setGroup',

            })
        },
    }
</script>
