import axios from 'axios'
import { wrapRequest } from './helpers'
import store from "Store";
import qs from 'querystring'
import getToken from "Plugins/recaptcha";

export const askNewPassword = async (data) => {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const recaptcha = await getToken()
    const requestBody = qs.stringify({...data, recaptcha, tokenForm : store.state.auth.tokenForm})
    return axios.post('askNewMdp.do', requestBody, config)
}


export const wrappedAskNewPassword = wrapRequest(askNewPassword);
/*
export const apiFetchStore = async ({ commit, url, params = {}, mutationTypes }) => {

    commit(mutationTypes.PENDING)

    try {
        const { data } = await axios.get(url, params)
        commit(mutationTypes.SUCCESS, data)
    } catch (error) {
        commit(mutationTypes.FAILURE, error.message)
    }

}*/
