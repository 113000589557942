<template>
   <div>
      <stay-localities-suggest
              :suggestions="stayLocalitiesAsArray"
              @selected="onLocalitySelected"
      />
   </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import StayLocalitiesSuggest from "./components/ui/StayLocalitiesSuggest/StayLocalitiesSuggest";


    export default {
        name: "StayLocalitiesSelector",
        components: {
            StayLocalitiesSuggest
        },
        computed:{
           ...mapGetters({
              stayLocalitiesAsArray : 'mr1/stayLocalities/stayLocalitiesAsArray',
              isMobile : 'breakpoint/isMobile'
           }),
        },
       methods: {
           ...mapActions({
              closeInput : 'mr1/closeInput',
              openInputDates : 'mr1/openInputDates',
              setSelected :'mr1/stayLocalities/setSelected',

           }),
          onLocalitySelected(id){
             this.setSelected(id)
             this.closeInput()
          }
       }
    }
</script>
