<template>
    <b-form-select
        :options="stayMonthsOptions"
        @change="change"
        :value="curMonth"
    />
</template>

<script>
    import dayjs from 'dayjs'
    import { BFormSelect } from 'bootstrap-vue'
    import { mapActions, mapGetters } from "vuex"

    export default {
        name: "StayMonthSelector",
        components : {
            BFormSelect
        },
        computed : {
            ...mapGetters({
                stayMonths : 'mr1/stayDates/monthsFromFirstToLastStay',
                showDate : 'mr1/showDate/showDate'
            }),
            stayMonthsOptions(){
              // On construit un object avec les années en key
              // pour facilement s'y retrouver
              // {
              //    2022 : {
              //        label : '2022'
              //      options : []
              //    },
              //    2023 : ...
              // }
              const yearsWithOptions = this.stayMonths.reduce((options, month) => {
                const monthDate = dayjs(month);
                if(!options[monthDate.year()]){
                  options[monthDate.year()] = {
                    label : monthDate.year(),
                    options : []
                  }
                }

                options[monthDate.year()].options.push({
                  value: month,
                  text: monthDate.format('MMMM YYYY')
                })

                return options;
              }, {});
              // On flatten le tout pour en faire un array sans les années en key
              return Object.keys(yearsWithOptions).map((year) => yearsWithOptions[year]);
            },
            curMonth(){
                return dayjs(this.showDate).date(1).format("YYYY-MM-DD")
            }
        },
        methods : {
            ...mapActions({
                'closeInput' : 'mr1/closeInput',
                'change' : 'mr1/showDate/setDate'
            })
        }

    }
</script>
