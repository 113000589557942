<template>
    <b-form
            action="/rechercheMR1.do"
            method="POST"
            id="mr1"
            @submit="onSubmit"
    >
        <stay-group-button
                :dogCount="dogCount"
                :personCount="personCount"
                :hasCompleted="hasCompletedGroup"
                @open="openInputGroup"
                @remove="resetGroup"
        />
        <stay-date-button
                @open="openInputDates"
                @remove="resetDate"
                :stayDate="stayDateSelected"
                :isCompleted="isDateCompleted"/>
        <stay-locality-button
                :hasCompleted="hasCompletedLocality"
                :noPreference="noPreferenceLocality"
                @open="openInputLocalities"
                @remove="removeLocalitySelected"
                :localityName="selectedLocalityName"
        />

        <search-button/>

        <inputs/>
    </b-form>
</template>

<script>
    import { BForm }from 'bootstrap-vue'

    import { mapState, mapGetters, mapActions } from "vuex"

    import launchResearch from "Utils/search/launcher"

    import StayLocalityButton from "./components/ui/StayLocalityButton"
    import StayDateButton from "./components/ui/StayDateButton"
    import StayGroupButton from "./components/ui/StayGroupButton"

    import SearchButton from "./components/ui/SearchButton"
    import Inputs from "./components/container/Inputs"

    export default {
        name: "FormMR1",
        components : {
            BForm,

            StayLocalityButton,
            StayDateButton,
            StayGroupButton,

            SearchButton,

            Inputs
        },
        computed : {
            ...mapState('mr1', {
                personCount: ({stayGroup: {personCount}}) => (personCount),
                dogCount: ({stayGroup: {dogCount}}) => (dogCount),
            }),
            ...mapGetters({
                selectedLocality : 'mr1/stayLocalities/selectedLocality',
                noPreferenceLocality : 'mr1/stayLocalities/noPreference',
                hasCompletedLocality : 'mr1/stayLocalities/hasCompleted',
                hasCompletedGroup : 'mr1/stayGroup/hasCompleted',
                stayDateSelected : 'mr1/stayDateSelected/getStayDate',
                isDateCompleted : 'mr1/stayDateSelected/isSomethingCompleted',
            }),
            selectedLocalityName(){
                return this.selectedLocality ? this.selectedLocality.name : null
            }
        },
        methods : {
            ...mapActions({
                removeLocalitySelected : 'mr1/stayLocalities/removeLocalitySelected',
                openInputLocalities : 'mr1/openInputLocalities',
                openInputDates : 'mr1/openInputDates',
                openInputGroup : 'mr1/openInputGroup',
                resetGroup : 'mr1/stayGroup/reset',
                resetDate : 'mr1/stayDateSelected/reset'
            }),
            onSubmit(e){
                e.preventDefault()
                this.trackSearch();
                launchResearch()
            },
            trackSearch() {
              let category = this.$store.state.page.page;

              if(category == "Home")
                category = 'homepage'
              if(category == "Recherche")
                category = 'lr'

              dataLayer.push({
                'event': 'GAEvent',
                'eventCategory': category,
                'eventAction': 'click',
                'eventLabel': 'search'
              });
            }
        },
    }
</script>

<style scoped>

</style>
