export default {
    // BASE
    BASE_PAGE_LOADED : 'BASE_PAGE_LOADED',
    // PRODUCT
    PRODUCT_STAYGROUP_SET_PERSONCOUNT : 'PRODUCT_STAYGROUP_SET_PERSONCOUNT',
    PRODUCT_STAYGROUP_SET_DOGCOUNT : 'PRODUCT_STAYGROUP_SET_DOGCOUNT',
    PRODUCT_STAYTYPE_SET : 'PRODUCT_STAYTYPE_SET',
    PRODUCT_AVAILABLESTAYS_SET : 'PRODUCT_AVAILABLESTAYS_SET',
    PRODUCT_SELECTEDSTAY_SET : 'PRODUCT_SELECTEDSTAY_SET',
    PRODUCT_PRICEBOX_TOGGLE : 'PRODUCT_PRICEBOX_TOGGLE',

    PRODUCT_STAYDATE_SET : 'PRODUCT_STAYDATE_SET',

    // LIVECHAT
    LIVECHAT_START : 'LIVECHAT_START',
    LIVECHAT_AGENTS_ONLINE : 'LIVECHAT_AGENTS_ONLINE',
    LIVECHAT_AGENTS_OFFLINE : 'LIVECHAT_AGENTS_OFFLINE',
    LIVECHAT_VISITOR_ENGAGED : 'LIVECHAT_VISITOR_ENGAGED',
    LIVECHAT_VISITOR_NOT_ENGAGED : 'LIVECHAT_VISITOR_NOT_ENGAGED',
    LIVECHAT_LAUNCH_GREETING : 'LIVECHAT_LAUNCH_GREETING',

    // LOADER
    LOADER_START : 'LOADER_START',
    LOADER_END : 'LOADER_END',
    // AUTH
    // LOGIN
    AUTH_LOGIN_OPEN_EXISTING_USER : 'AUTH_LOGIN_OPEN_EXISTING_USER',

    // BREAKPOINT
    BREAKPOINT_CHANGE : 'BREAKPOINT_CHANGE',

    // MODAL
    MODAL_SET_GENERIC : 'MODAL_SET_GENERIC',
    MODAL_GENERIC_OPEN : 'MODAL_GENERIC_OPEN',
    MODAL_OPEN : 'MODAL_OPEN',
    MODAL_CLOSE : 'MODAL_CLOSE',

    // FAVORITES HOUSE

    FAVORITES_HOUSE_OPEN : 'FAVORITES_HOUSE_OPEN',
    FAVORITES_HOUSE_CLOSE : 'FAVORITES_HOUSE_CLOSE',
    FAVORITES_HOUSE_ADD : 'FAVORITES_HOUSE_ADD',
    FAVORITES_HOUSE_REMOVE : 'FAVORITES_HOUSE_REMOVE',

    // MR1

        OPEN_INPUT : 'mr1OpenInput',
        CLOSE_INPUT : 'mr1CloseInput',

        MR1_OPEN_MODAL : 'MR1_OPEN_MODAL',
        MR1_CLOSE_MODAL : 'MR1_CLOSE_MODAL',


        // MODAL OPEN


        // STAYS DATE

        MR1_STAYDATES_GET_START : 'MR1_STAYDATES_GET_START',
        MR1_STAYDATES_GET_SUCCESS : 'MR1_STAYDATES_GET_SUCCESS',
        MR1_STAYDATES_GET_FAILURE : 'MR1_STAYDATES_GET_FAILURE',

        // STAYS MONTHS
        MR1_STAYMONTHS_FETCH_START : 'mr1StaysMonthsFetchStart',
        MR1_STAYMONTHS_FETCH_END : 'mr1StaysMonthsFetchEnd',
        MR1_STAYMONTHS_SET_DATA : 'mr1StaysMonthsSetData',

        // STAY GROUP
        MR1_STAYGROUP_SET_PERSONCOUNT : 'MR1_STAYGROUP_SET_PERSONCOUNT',
        MR1_STAYGROUP_SET_DOGCOUNT : 'MR1_STAYGROUP_SET_DOGCOUNT',
        MR1_STAYGROUP_RESET : 'MR1_STAYGROUP_RESET',

        // SHOW DATE
        MR1_SHOW_DATE_SET : 'MR1_SHOW_DATE_SET',
        // STAY DATE SELECTED
        MR1_STAYDATE_SELECTED_SET : 'MR1_STAYDATE_SELECTED_SET',
        MR1_STAYDATE_SELECTED_RESET : 'MR1_STAYDATE_SELECTED_RESET',
        MR1_STAYDATE_REMOVE_DATE : 'MR1_STAYDATE_REMOVE_DATE',
        // STAY TYPE
        MR1_STAYDATE_TYPE_SELECTED_SET : 'MR1_STAYDATE_TYPE_SELECTED_SET',
        // MONTH
        MR1_STAYDATE_SET_MONTH : 'MR1_STAYDATE_SET_MONTH',
        MR1_STAYDATE_REMOVE_MONTH : 'MR1_STAYDATE_REMOVE_MONTH',

        // STAY LOCALITIY SELECTED
        MR1_STAY_LOCALITIES_SELECTED_SET : 'MR1_STAY_LOCALITIES_SELECTED_SET',
        MR1_STAY_LOCALITIES_SET_NO_PREFERENCE : 'MR1_STAY_LOCALITIES_SET_NO_PREFERENCE',
        MR1_STAY_LOCALITIES_SELECTED_UNSET : 'MR1_STAY_LOCALITIES_SELECTED_UNSET',

    // MR2
    MR2_FILTER_ADD : 'MR2_FILTER_ADD',
    MR2_FILTER_REMOVE : 'MR2_FILTER_REMOVE',

    // TOAST

    TOAST_ADD : 'TOAST_ADD',
    TOAST_REMOVE : 'TOAST_REMOVE',
    TOAST_INCREASE : 'TOAST_INCREASE',

    // BOOKING
    BOOKING_STEP_CHANGE : 'BOOKING_STEP_CHANGE',

    // PRODUCT
    PRODUCT_OPEN_INPUT : 'PRODUCT_OPEN_INPUT',
    PRODUCT_CLOSE_INPUT : 'PRODUCT_CLOSE_INPUT',

    PRODUCT_OPEN_MODAL : 'PRODUCT_OPEN_MODAL',
    PRODUCT_CLOSE_MODAL : 'PRODUCT_CLOSE_MODAL',

    PRODUCT_OPEN_FULLSCREEN_GALLERY : 'PRODUCT_OPEN_FULLSCREEN_GALLERY',
    PRODUCT_CLOSE_FULLSCREEN_GALLERY : 'PRODUCT_CLOSE_FULLSCREEN_GALLERY',
}
